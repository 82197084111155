import { SubscriptionStatusEnum } from "../customerMembership-v1.typings";

import {
	ISubscription,
	ISubscriptionHeader,
	ISubscriptionListState,
	ISubscriptionMember,
	IPlanPrices,
	BillingCycleEnum,
} from "../customerMembership.typings";
import { layoutReducer } from "./layout.reducer";

// HELPERS
import { transientStates } from "./transient.reducer";

export const subscriptionStates = {
	subscription: {
		createStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		expiringStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		paymentStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		removeStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		resumeStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		saveStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			shouldFire: false,
			releaseHold: false,
		},
		autoSave: false,
		billingInformation: {
			accountOwnerId: 0,
			accountTokenId: 0,
			billingCyclePeriod: BillingCycleEnum.Monthly,
			nextBillingDate: undefined,
			firstName: "",
			lastName: "",
			fullName: "",
			email: "",
			scheduledBillingCycle: undefined,
		},
		cancelledOn: undefined,
		createdByUserId: 0,
		createdOn: undefined,
		expiringDate: undefined,
		facilityId: 0,
		isActive: false,
		isCancelled: false,
		isPlanActive: false,
		isSetupComplete: false,
		lastBillAmount: 0,
		lastBillOn: undefined,
		members: [] as Array<ISubscriptionMember>,
		modifiedByUserId: 0,
		modifiedOn: undefined,
		nextBillingDate: undefined,
		ownerAccount: {
			accountId: 0,
			email: "",
			firstName: "",
			fullName: "",
			lastName: "",
			accountTokens: [],
		},
		plan: {
			activationFee: {
				activationFee: 0,
				activationFeeName: "",
			},
			isMonthly: false,
			isYearly: false,
			planColor: "",
			planId: 0,
			planName: "",
			membershipPrices: [{} as IPlanPrices],
			productId: 0,
			receiptTemplateId: 0,
			receiptTemplateName: "",
			welcomeTemplateId: 0,
			welcomeTemplateName: "",
			isActive: false,
		},
		subscriptionId: 0,
		subscriptionStatus: SubscriptionStatusEnum.Inactive,
		subscriptionStatusDate: undefined,
	} as ISubscription,
	subscriptions: {
		fetchStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		items: [] as Array<ISubscriptionHeader>,
		selectedSubscriptionId: 0,
	} as ISubscriptionListState,
};

export const subscriptionReducer = () => {
	return {
		fetchSubscriptionStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchSubscriptionComplete: (state, { payload }) => {
			const {
				transientMembers,
				transientBillingInformation,
				transientAccountOwner,
			} = payload;

			return {
				...state,
				subscription: {
					...state.subscription,
					...payload.subscription,
					fetchStateLoading: {
						...state.subscription.fetchStateLoading,
						started: true,
						loading: false,
						success: true,
					},
				},
				transientMembers: {
					...state.transientMembers,
					fetchStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
					items: transientMembers,
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientAccountOwner: {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items,
					},
				},
				flagStatus: {
					...state.flagStatus,
					memberCardFlags: {
						hasChangedCommunication: false,
						hasNewMembers: false,
						hasRemovedMembers: false,
						membersCardStatus: undefined,
						membersCardWarningMessage: "",
					},
					billingCardFlags: {
						billingCardStatus: undefined,
						billingCardWarnningMessage: "",
						hasChangedAccountOwner: false,
						hasChangedAccountToken: false,
						hasChangedBillingCycle: false,
					},
				},
			};
		},
		fetchSubscriptionFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					fetchStateLoading: {
						...state.subscription.fetchStateLoading,
						loading: false,
						started: true,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		addMembershipPlanStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					createStateLoading: {
						...state.subscription.createStateLoading,
						loading: true,
					},
				},
				// addMembershipPlanSuccess: false,
				// isAddingMembershipPlan: true,
				// error: "",
				// selectedAccountTokenId: 0
			};
		},
		addMembershipPlanSuccess: (state, { payload }) => {
			const {
				subscription: subscriptionData,
				subscriptionsHeader,
				transientAccountOwner,
				transientBillingInformation,
				transientMembers,
			} = payload;

			return {
				...state,
				subscription: {
					...state.subscription,
					...subscriptionData,
					createStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: true,
					},
					fetchStateLoading: {
						...state.subscription.fetchStateLoading,
						started: true,
					},
					billingInformation: {
						...state.subscription.billingInformation,
						accountOwnerId:
							subscriptionData.billingInformation.accountOwnerId,
						accountTokenId:
							subscriptionData.billingInformation.accountTokenId,
						billingCyclePeriod:
							subscriptionData.billingInformation.billingCyclePeriod,
					},
					plan: {
						...subscriptionData.plan,
						activationFee: subscriptionData.plan.activationFee,
						prices: subscriptionData.plan.membershipPrices,
					},
				},
				subscriptions: {
					...state.subscriptions,
					items: subscriptionsHeader,
					selectedSubscriptionId: subscriptionData.subscriptionId,
				},
				transientAccountOwner: {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items,
					},
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientMembers: {
					...state.transientMembers,
					items: transientMembers,
				},
				layout: {
					...state.layout,
					isAddSubscriptionDrawerOpen: false,
				},
				pricingAndFees: {
					fetchStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
						shouldFire: false,
					},
					recurringBillingAmount: 0,
					recurringBillingAmountAfterTaxes: 0,
					payingNowAmount: 0,
					payingNowAmountAfterTaxes: 0,
					pricingTiers: [],
					proRatedPricingTiers: [],
					taxes: [],
				},
			};
		},
		addMembershipPlanFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					createStateLoading: {
						errorMessage: payload,
						hasError: true,
						loading: false,
						success: false,
					},
				},
			};
		},
		/// /

		fetchSubscriptionListStart: (state, { payload }) => {
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					fetchStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchSubscriptionListComplete: (state, { payload }) => {
			// TODO: Create logic for selected Subscription here
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					items: payload,
					fetchStateLoading: {
						...state.subscriptions.fetchStateLoading,
						loading: false,
						success: true,
					},
				},
			};
		},
		fetchSubscriptionListFail: (state, { payload }) => {
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					fetchStateLoading: {
						...state.subscriptions.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resumeSubscriptionStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					resumeStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		resumeSubscriptionComplete: (state, { payload }) => {
			const {
				subscription,
				transientAccountOwner,
				transientBillingInformation,
				transientMembers,
			} = payload;

			return {
				...state,
				subscription: {
					...state.subscription,
					...subscription,
					resumeStateLoading: {
						...state.subscription.removeStateLoading,
						loading: false,
						success: true,
					},
				},
				transientAccountOwner: {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items,
					},
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientMembers: {
					...state.transientMembers,
					items: transientMembers,
				},
			};
		},
		resumeSubscriptionFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					resumeStateLoading: {
						...state.subscription.resumeStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		removeSubscriptionStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					removeStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		removeSubscriptionComplete: (state, { payload }) => {
			const {
				subscription,
				subscriptionHeaders,
				transientMembers,
				transientAccountOwner,
				transientBillingInformation,
			} = payload;

			let newSubscription = subscriptionStates.subscription;
			let newSubscriptions = subscriptionStates.subscriptions.items;
			let newSelectedSubscriptionId =
				subscriptionStates.subscriptions.selectedSubscriptionId;
			const newTransientAccountOwner =
				transientAccountOwner || state.transientAccountOwner;

			if (subscription) {
				newSubscription = subscription;
				newSubscriptions = subscriptionHeaders;
			}

			if (subscriptionHeaders) {
				newSelectedSubscriptionId = subscriptionHeaders[0].subscriptionId;
			}

			return {
				...state,
				subscription: {
					...state.subscription,
					...newSubscription,
					removeStateLoading: {
						...state.subscription.removeStateLoading,
						loading: false,
						success: true,
					},
				},
				subscriptions: {
					items: newSubscriptions,
					fetchStateLoading: state.subscriptions.fetchStateLoading,
					selectedSubscriptionId: newSelectedSubscriptionId,
				},
				transientMembers: {
					items: transientMembers || state.transientMembers.items,
					fetchStateLoading: state.transientMembers.fetchStateLoading,
				},
				transientAccountOwner: {
					...newTransientAccountOwner,
					fetchStateLoading: state.transientAccountOwner.fetchStateLoading,
					tokenCanBeDeletedStateLoading:
						state.transientAccountOwner.tokenCanBeDeletedStateLoading,
					accountTokens: {
						removeTokenStateLoading:
							state.transientAccountOwner.accountTokens
								.removeTokenStateLoading,
						saveTokenStateLoading:
							state.transientAccountOwner.accountTokens
								.saveTokenStateLoading,
						items: newTransientAccountOwner.items,
					},
				},
				transientBillingInformation:
					transientBillingInformation || state.transientBillingInformation,
			};
		},
		removeSubscriptionFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					removeStateLoading: {
						...state.subscription.removeStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},

		/// /
		saveSubscriptionStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
						shouldFire: false,
						releaseHold: false,
					},
				},
			};
		},
		saveSubscriptionComplete: (state, { payload }) => {
			window.parent.parent.postMessage("HideWaitOverlay", "*");
			window.parent.parent.postMessage("SetFormAsClear", "*");
			const {
				transientMembers,
				transientBillingInformation,
				transientAccountOwner,
				subscription,
			} = payload;

			const shouldFire = subscription.autoSave !== state.subscription.autoSave;

			// computed Subscription
			let finalSubscription = subscriptionStates.subscription;

			if (subscription) {
				finalSubscription = {
					...state.subscription,
					...subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						loading: false,
						success: !subscription.autoSave,
						shouldFire: false,
					},
					paymentStateLoading: {
						...state.subscription.saveStateLoading,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				};
			}

			// computed transientAccountOwner
			let finalTransientAccountOwner = transientStates.transientAccountOwner;

			if (transientAccountOwner) {
				finalTransientAccountOwner = {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items || [],
					},
				};
			}

			return {
				...state,
				subscription: finalSubscription,
				subscriptions: {
					...state.subscriptions,
					fetchStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
					items: payload.subscriptionHeaders,
				},
				transientMembers: {
					...state.transientMembers,
					fetchStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
					items: transientMembers || [],
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientAccountOwner: finalTransientAccountOwner,
				flagStatus: {
					...state.flagStatus,
					memberCardFlags: {
						hasChangedCommunication: false,
						hasNewMembers: false,
						hasRemovedMembers: false,
						membersCardStatus: undefined,
						membersCardWarningMessage: "",
					},
					billingCardFlags: {
						billingCardStatus: undefined,
						billingCardWarnningMessage: "",
						hasChangedAccountOwner: false,
						hasChangedAccountToken: false,
						hasChangedBillingCycle: false,
					},
				},
				layout: {
					isAddSubscriptionDrawerOpen: false,
					isBillingDrawerOpen: false,
					isMembersDrawerOpen: false,
					isMemberDeleteModalOpen: false,
					isPlanOwnerModalOpen: false,
					isTokenDrawerOpen: false,
					isPayErrorModalOpen: false,
					isPaymentDrawerOpen: false,
				},
				pricingAndFees: {
					...state.pricingAndFees,
					fetchStateLoading: {
						...state.pricingAndFees.fetchStateLoading,
						shouldFire,
					},
				},
			};
		},
		saveSubscriptionFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
						shouldFire: false,
					},
					paymentStateLoading: {
						...state.subscription.saveStateLoading,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		saveSubscriptionShouldFire: (state) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						shouldFire: true,
					},
				},
			};
		},
		releaseSubscriptionSaveHold: (state) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						releaseHold: true,
					},
				},
			};
		},
		cancelSubscriptionStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					expiringStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: true,
						success: false,
					},
				},
			};
		},
		cancelSubscriptionComplete: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					...payload,
					expiringStateLoading: {
						...state.subscription.expiringStateLoading,
						loading: false,
						success: true,
					},
				},
			};
		},
		cancelSubscriptionFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					expiringStateLoading: {
						errorMessage: payload,
						hasError: true,
						loading: false,
						success: false,
					},
				},
			};
		},
		cancelImmediatelyStart: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					expiringStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: true,
						success: false,
					},
				},
			};
		},
		cancelImmediatelyComplete: (state, { payload }) => {
			const {
				subscription,
				subscriptionHeaders,
				transientMembers,
				transientBillingInformation,
				transientAccountOwner,
			} = payload;

			let finalSubscription;

			if (!subscription) {
				finalSubscription = {
					...subscriptionStates.subscription,
					expiringStateLoading: {
						...subscriptionStates.subscription.expiringStateLoading,
						loading: false,
						success: true,
					},
				};
			} else {
				finalSubscription = {
					...state.subscription,
					...payload.subscription,
					expiringStateLoading: {
						...state.subscription.expiringStateLoading,
						loading: false,
						success: true,
					},
				};
			}

			let finalTransientAccountOwner = transientStates.transientAccountOwner;

			if (transientAccountOwner) {
				finalTransientAccountOwner = {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items || [],
					},
				};
			}

			return {
				...state,
				subscription: finalSubscription,
				subscriptions: {
					...state.subscriptions,
					items: subscriptionHeaders || [],
				},
				flagStatus: {
					...state.flagStatus,
					memberCardFlags: {
						hasChangedCommunication: false,
						hasNewMembers: false,
						hasRemovedMembers: false,
						membersCardStatus: undefined,
						membersCardWarningMessage: "",
					},
					billingCardFlags: {
						billingCardStatus: undefined,
						billingCardWarnningMessage: "",
						hasChangedAccountOwner: false,
						hasChangedAccountToken: false,
						hasChangedBillingCycle: false,
					},
				},
				transientMembers: {
					...state.transientMembers,
					fetchStateLoading: {
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
					items: transientMembers || [],
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientAccountOwner: finalTransientAccountOwner,
			};
		},
		cancelImmediatelyFail: (state, { payload }) => {
			return {
				...state,
				subscription: {
					...state.subscription,
					expiringStateLoading: {
						errorMessage: payload,
						hasError: true,
						loading: false,
						success: false,
					},
				},
			};
		},
		/// /
		paySubscriptionStart: (state, { payload }) => {
			window.parent.parent.postMessage("ShowWaitOverlay", "*");
			return {
				...state,
				subscription: {
					...state.subscription,
					paymentStateLoading: {
						...state.subscription.paymentStateLoading,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		paySubscriptionFail: (state, { payload }) => {
			window.parent.parent.postMessage("HideWaitOverlay", "*");
			return {
				...state,
				subscription: {
					...state.subscription,
					paymentStateLoading: {
						...state.subscription.paymentStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload.error.data ?? "An error occurred",
						shouldFire: false,
					},
				},
				layout: {
					...layoutReducer,
					isPayModalOpen: true,
				},
			};
		},
	};
};
